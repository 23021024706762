import {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useRef,
  useState,
} from "react";
import GoBackButton from "../../common/GoBackButton/GoBackButton";
import { useNavigate } from "react-router-dom";
import { Box, Button, Stack, Typography } from "@mui/material";
import {
  OTPInput,
  PsButton,
  PsInput,
} from "../../../lib/psElements/psElements";

interface phoneInputForLoginProps {
  handlerSendOTP: () => void;
  phoneNumber: String;
  setPhoneNumber: Dispatch<SetStateAction<string>>;
}

interface oTPForPhoneNumberProps {
  phoneNumber: String;
}
const OTPForPhoneNumber: FunctionComponent<oTPForPhoneNumberProps> = ({
  phoneNumber,
}) => {
  const [otpString, setOtpString] = useState("");
  const [otp1, setOtp1] = useState("");
  const [otp2, setOtp2] = useState("");
  const [otp3, setOtp3] = useState("");
  const [otp4, setOtp4] = useState("");
  const [otp5, setOtp5] = useState("");
  const [otp6, setOtp6] = useState("");

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);

  const handlerOTPInput = (e: any) => {
    const name = e?.target?.name;
    const value = e?.target?.value;
    const ename = name.split("-")[1];
    console.log(ename);
    switch (ename) {
      case "1":
        setOtp1(value);
        break;
      case "2":
        setOtp2(value);
        break;
      case "3":
        setOtp3(value);
        break;
      case "4":
        setOtp4(value);
        break;
      case "5":
        setOtp5(value);
        break;
      case "6":
        setOtp6(value);
        break;
      default:
        break;
    }
    setOtpString(otp1 + otp2 + otp3 + otp4 + otp5 + otp6);
  };
  return (
    <>
      <Stack direction="column" sx={{ gap: "2.5rem" }}>
        <span className="leftAlign">
          An OTP has been sent to +91-{phoneNumber}
        </span>
        <div className="otpInputGroup">
          <OTPInput
            type="text"
            name="otp-1"
            onChange={(e) => handlerOTPInput(e)}
            value={otp1}
            ref={ref1}
            inputProps={{ maxLength: 1 }}
          />
          <OTPInput
            type="text"
            name="otp-2"
            onChange={(e) => handlerOTPInput(e)}
            value={otp2}
            ref={ref2}
            inputProps={{ maxLength: 1 }}
          />
          <OTPInput
            type="text"
            name="otp-3"
            onChange={(e) => handlerOTPInput(e)}
            value={otp3}
            ref={ref3}
            inputProps={{ maxLength: 1 }}
          />
          <OTPInput
            type="text"
            name="otp-4"
            onChange={(e) => handlerOTPInput(e)}
            value={otp4}
            ref={ref4}
            inputProps={{ maxLength: 1 }}
          />
          <OTPInput
            type="text"
            name="otp-5"
            onChange={(e) => handlerOTPInput(e)}
            value={otp5}
            ref={ref4}
            inputProps={{ maxLength: 1 }}
          />
          <OTPInput
            type="text"
            name="otp-6"
            onChange={(e) => handlerOTPInput(e)}
            value={otp6}
            ref={ref4}
            inputProps={{ maxLength: 1 }}
          />
        </div>
        {/* <Button variant="text"> This is text button</Button> */}
        <Typography align="right" className="cursorPointer">
          Resent OTP
        </Typography>
        <PsButton.Primary variant="contained" sx={{ height: "3rem" }}>
          Submit
        </PsButton.Primary>
      </Stack>
    </>
  );
};

const PhoneInputForLogin: FunctionComponent<phoneInputForLoginProps> = ({
  handlerSendOTP,
}) => {
  return (
    <Stack direction="column" sx={{ gap: "2.5rem" }}>
      <PsInput label="Phone Number" type="number" variant="outlined" />
      <PsButton.Primary
        variant="contained"
        className="hoverOrange"
        onClick={() => {
          handlerSendOTP();
        }}
        sx={{ height: "3rem" }}
      >
        Send OTP
      </PsButton.Primary>
    </Stack>
  );
};

function LoginByPhoneNumber() {
  const [otpWindow, setOtpWindow] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const navigate = useNavigate();
  const handlerSendOTP = () => {
    console.log("handlerSendOTP");
    setOtpWindow(true);
  };
  const handlerBackButton = () => {
    if (otpWindow) setOtpWindow(false);
    else navigate("/authorization/login");
  };
  return (
    <Box className="loginByPhoneNumber">
      <GoBackButton handlerBackButton={handlerBackButton} />
      <div>
        <h1 className="psHeading h1">Login with Phone Number</h1>
      </div>
      {!otpWindow && (
        <PhoneInputForLogin
          {...{ handlerSendOTP, phoneNumber, setPhoneNumber }}
        />
      )}
      {otpWindow && <OTPForPhoneNumber {...{ phoneNumber }} />}
    </Box>
  );
}

export default LoginByPhoneNumber;
