import { FunctionComponent } from "react";
import StepsBar from "./StepsBar";
import {
  BoardCard,
  PsButton,
  PsInput,
  StepBox,
} from "../../../lib/psElements/psElements";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid2,
  Pagination,
  PaginationItem,
  Stack,
  Typography,
} from "@mui/material";
import { GoBackArrowIos } from "../../common/GoBackButton/GoBackButton";
import { useNavigate } from "react-router-dom";
import { PsTheme } from "../../../styles/psMuiTheme/colorCodes";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import leftIcon from "./../../assets/images/paginationLeftIcon.svg";
import rightIcon from "./../../assets/images/paginationRightIcon.svg";
import elipseWhite from "./../../assets/images/paginationElipseWhite.svg";
import elipseOrange from "./../../assets/images/paginationElipseOrange.svg";

const LanguageDetails: FunctionComponent = () => {
  const navigate = useNavigate();
  const boardNames = ["English", "Hindi", "Marathi", "Cannada", "Gujrati"];

  const navigateToGradeDetails = () => {
    navigate("/register/steps/gradedetails");
  };

  const navigateToBoardDetails = () => {
    navigate("/register/steps/boarddetails");
  };

  return (
    <div className="schoolDetails">
      <StepsBar currentStep={3} />
      <StepBox>
        <GoBackArrowIos
          handlerBackButton={() => {
            navigateToBoardDetails();
          }}
          sx={{ ml: 2, mt: 2, mb: 1 }}
        />
        <Typography
          align="left"
          sx={{ ml: 3, fontWeight: 700, mb: 2.5 }}
          variant="h5"
        >
          In which language will you take your exams?
        </Typography>
        <Grid2
          container
          spacing={{ xs: 2, md: 2.5 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          sx={{ m: 3, mb: 21.75 }}
        >
          {boardNames.map((item) => {
            return (
              <Grid2 size={{ md: 3 }} sx={{ mt: 1.5 }}>
                <BoardCard variant="outlined">
                  <CardContent
                    sx={{ alignItems: "center", textAlign: "center" }}
                  >
                    <Avatar
                      sx={{
                        width: 80,
                        height: 80,
                        m: "auto",
                        backgroundColor: PsTheme.colors.boardCard.avatar,
                      }}
                      alt={item}
                      src="/static/images/avatar/1.jpg"
                    />
                    <Typography align="center" sx={{ width: "100%", mt: 1 }}>
                      {item}
                    </Typography>
                  </CardContent>
                </BoardCard>
              </Grid2>
            );
          })}
        </Grid2>
      </StepBox>
      <Stack direction={"row"} gap={1} sx={{ mt: 4.5 }} alignSelf={"flex-end"}>
        <PsButton.Outlined variant="outlined" sx={{ width: 120 }}>
          Cancel
        </PsButton.Outlined>
        <PsButton.Primary
          variant="contained"
          sx={{ width: 240 }}
          onClick={() => navigateToGradeDetails()}
        >
          Proceed
        </PsButton.Primary>
      </Stack>
    </div>
  );
};
export default LanguageDetails;
