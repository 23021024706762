import { Box } from "@mui/material";
import leftImage from "../../assets/images/authorization/leftSectionCompressed.jpeg";
import logo from "../../assets/svg/logo.svg";

function LeftSection() {
  return (
    <Box className="leftSection">
      <img className="background" src={leftImage} alt="background" />
      <img className="logo" src={logo} alt="Pariksathi" />
    </Box>
  );
}

export default LeftSection;
