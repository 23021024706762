import GoBackButton from "../../common/GoBackButton/GoBackButton";
import eyeSvg from "../../assets/svg/authorization/Eye.svg";
import { useNavigate } from "react-router-dom";
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { PsButton, PsInput } from "../../../lib/psElements/psElements";
import { PsTheme } from "../../../styles/psMuiTheme/colorCodes";
import { css } from "@emotion/react";

function LoginByEmail() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const navigateToLogin = () => {
    navigate("/authorization/login");
  };
  const navigateToCreateAccount = () => {
    navigate("/authorization/create");
  };

  /*  const handleClickShowPassword = () => {
    setShowPassword(!setShowPassword);
  }

  const handleMouseDownPassword = () => {
    setShowPassword(false);
  }
  const handleMouseUpPassword = () => {
    setShowPassword(true);
  } */

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    // setShowPassword(true);
  };

  const handleMouseUpPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    // setShowPassword(false);
  };

  return (
    <Box>
      <GoBackButton handlerBackButton={navigateToLogin} />
      <Stack direction="column" sx={{ gap: "1.5rem" }}>
        <h1 className="psHeading h1">Login with email</h1>
        <PsInput type="email" variant="outlined" label="Email address" />
        <FormControl sx={{ m: 0, width: "100%" }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">
            Password
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label={
                    showPassword ? "hide the password" : "display the password"
                  }
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  onMouseUp={handleMouseUpPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>
        <Typography align="right" className="cursorPointer">
          Forgot password?
        </Typography>
        <PsButton.Primary variant="contained" sx={{ height: "3rem" }}>
          Login
        </PsButton.Primary>
        <span
          className="createAccount"
          onClick={() => navigateToCreateAccount()}
        >
          <Typography align="center" noWrap>
            Don’t have a account?&nbsp;
            <span
              style={{ color: PsTheme.colors.action.primary }}
              className="cursorPointer"
            >
              Create new account
            </span>
          </Typography>
        </span>
      </Stack>
    </Box>
  );
}

export default LoginByEmail;
