import { FunctionComponent, useRef, useState } from "react";
import greenCircle from "../../assets/svg/authorization/green-Success-dark.svg";
import { useNavigate } from "react-router-dom";
import { Box, Stack, Typography } from "@mui/material";
import { PsButton } from "../../../lib/psElements/psElements";
import leftBottomImage from "../../assets/images/authorization/boyGirlReadingBook.svg";
import logo from "../../assets/svg/logo.svg";
import { PsTheme } from "../../../styles/psMuiTheme/colorCodes";

const Congratulations: FunctionComponent = () => {
  const navigate = useNavigate();
  //   const [leftBottomImageFlag, setLeftBottomImageFlag] = useState(false);
  //   const leftBottomImageRef = useRef(null);

  const navigateToStep1PersonalDetails = () => {
    navigate("/register/steps/personaldetails");
  };
  return (
    <div
      className="congratulationContainer"
      style={{ background: PsTheme.colors.surface.five }}
    >
      <img className="logo" src={logo} alt="Pariksathi logo" />
      <Box className="congratulations">
        <Stack
          direction="column"
          className="centerCircle"
          gap={"1.5rem"}
          alignItems={"center"}
          sx={{ width: "42.4375rem" }}
        >
          <img src={greenCircle} alt="green circle" />
          <h1>Congratulations</h1>
          <Typography className="helpingText">
            On taking the first step towards preparing for your important exams
            sit back and relax, we have you covered.
          </Typography>
          <PsButton.Primary
            variant="contained"
            onClick={() => navigateToStep1PersonalDetails()}
            sx={{ height: "3rem", width: "25rem" }}
          >
            Continue
          </PsButton.Primary>
        </Stack>
      </Box>
    </div>
  );
};

export default Congratulations;
