import { FunctionComponent } from "react";
import StepsBar from "./StepsBar";
import { PsButton, PsInput, StepBox } from "../../../lib/psElements/psElements";
import { Grid2, Stack, Typography } from "@mui/material";
import { GoBackArrowIos } from "../../common/GoBackButton/GoBackButton";
import { useNavigate } from "react-router-dom";

const SchoolDetails: FunctionComponent = () => {
  const navigate = useNavigate();

  const navigateToPersonalDetails = () => {
    navigate("/register/steps/personaldetails");
  };

  const navigateToBoardDetails = () => {
    navigate("/register/steps/boarddetails");
  };

  return (
    <div className="schoolDetails">
      <StepsBar currentStep={1} />
      <StepBox>
        <GoBackArrowIos
          handlerBackButton={() => {
            navigateToPersonalDetails();
          }}
          sx={{ ml: 2, mt: 2, mb: 1 }}
        />
        <Typography
          align="left"
          sx={{ ml: 3, fontWeight: 700, mb: 2.5 }}
          variant="h5"
        >
          School
        </Typography>
        <Grid2
          container
          spacing={{ xs: 2, md: 2.5 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          sx={{ m: 3, mb: 21.75 }}
        >
          <Grid2 size={{ md: 12 }} sx={{ mt: 1.5 }}>
            <PsInput label="School" />
          </Grid2>
          <Grid2 size={{ md: 12 }} sx={{ mt: 1.5 }}>
            <PsInput label="Attended any tuition or coaching classes" />
          </Grid2>
        </Grid2>
      </StepBox>
      <Stack direction={"row"} gap={1} sx={{ mt: 4.5 }} alignSelf={"flex-end"}>
        <PsButton.Outlined variant="outlined" sx={{ width: 120 }}>
          Cancel
        </PsButton.Outlined>
        <PsButton.Primary
          variant="contained"
          sx={{ width: 240 }}
          onClick={() => navigateToBoardDetails()}
        >
          Proceed
        </PsButton.Primary>
      </Stack>
    </div>
  );
};
export default SchoolDetails;
