import React from "react";
import ReactDOM from "react-dom/client";
import { CssBaseline, ThemeProvider } from "@mui/material";
import * as ER from "@emotion/react";

import reportWebVitals from "./reportWebVitals";
import PsRoutes from "./app/routes/PsRoutes";
import { psDarkTheme } from "./styles/psMuiTheme/psMuiTheme";
import { darkTheme } from "./styles/psMuiTheme/colorCodes";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={psDarkTheme} defaultMode="dark">
      <ER.ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <PsRoutes />
      </ER.ThemeProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
