import { Fragment, ReactElement } from "react";
import { Container, createTheme } from "@mui/material";
import { Outlet } from "react-router-dom";
import "./../../styles/index.scss";

// const theme = createTheme({
//   components: {
//     MuiContainer: {
//       styleOverrides: {
//         root: {
//           "&.MuiContainer-maxWidthXl": {
//             maxWidth: "1440px",
//           },
//         },
//       },
//     },
//   },
// });

export const PSContainer: React.FC = (): ReactElement => {
  return (
    <Container maxWidth="xl">
      <Outlet />
    </Container>
  );
};
