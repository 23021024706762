import { FunctionComponent } from "react";
import { Outlet } from "react-router-dom";

const StepContainer: FunctionComponent = () => {
  return (
    <div className="stepsContainer">
      <h1 className="stepsHeading">Let us know you a bit more</h1>
      <Outlet />
    </div>
  );
};

export default StepContainer;
