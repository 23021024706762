import { Grid2, Stack } from "@mui/material";
import LeftSection from "../components/Authorization/LeftSection";
import RightSection from "../components/Authorization/RightSection";

export function Authorization() {
  return (
    <Stack direction="row" className="psAuthorization">
      <LeftSection />
      <RightSection />
    </Stack>
  );
}

{
  /* <Grid2 container className="psAuthorization">
      <Grid2 size={6}>
        <LeftSection />
      </Grid2>
      <Grid2 size={6}>
        <RightSection />
      </Grid2>
    </Grid2> */
}
{
  /* <Stack direction="row" className="psAuthorization">
      <LeftSection />
      <RightSection />
    </Stack> */
}
