import { createTheme } from "@mui/material";

const primaryDark = {
  main: "#3f7df9",
  light: "#42a5f5",
  dark: "#1565c0",
  contrastText: "#fff",
};
const secondaryDark = {
  main: "#1976d2",
  light: "#42a5f5",
  dark: "#1565c0",
  // contrastText: "#fff",
};

// {
//   dark: "#181818",
//   main: "#181818",
//   light: "#2A2A2A",
// },

export const psDarkTheme = createTheme({
  colorSchemes: {
    dark: {
      palette: {
        primary: primaryDark,
    //     secondary: secondaryDark,
      },
    },
  },
});
// export const psDarkTheme = createTheme({
//   colorSchemes: {
//     dark: {
//       palette: {
//         primary: primaryDark,
//         secondary: secondaryDark,
//       },
//     },
//   },
//   components: {
//     MuiContainer: {
//       styleOverrides: {
//         root: {
//           "&.MuiContainer-maxWidthXl": {
//             maxWidth: "1440px",
//           },
//         },
//       },
//     },
//     MuiButton: {
//       styleOverrides: {
//         root: {
//           variants: [
//             {
//               props: (props) => props.variant === "contained",
//               style: {
//                 textTransform: "none",
//                 background: "#2A2A2A",
//                 backgroundColor: "primary.light",
//                 height:'2.5rem',
//                 fontFamily:'inter',
//                 borderRadius:'0.5rem',
//                 // ':hover': {
//                 //   border: '1px solid #FD9B18',
//                 //   background: '#FD9B18',
//                 //   opactity: '90%',
//                 // }
//               },
//             },
//           ],
//         },
//       },
//     },
//   },
// });
