import { Outlet } from "react-router-dom";
import LoginByEmail from "./LoginByEmail";
import LoginByPhoneNumber from "./LoginByPhoneNumber";
import SelectLoginType from "./SelectLoginType";
import { Box } from "@mui/material";
import { PsBox } from "../../../lib/psElements/psElements";

function RightSection() {
  return (
    <PsBox className="rightSection" sx={{ width: "100%" }}>
      <Outlet />
      {/* <SelectLoginType /> */}
      {/* <LoginByEmail /> */}
      {/* <LoginByPhoneNumber /> */}
      {/* </div> */}
    </PsBox>
  );
}
export default RightSection;
