import { FunctionComponent, useState } from "react";
import GoBackButton from "../../common/GoBackButton/GoBackButton";
import { useNavigate } from "react-router-dom";
import eyeSvg from "../../assets/svg/authorization/Eye.svg";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import { PsButton, PsInput } from "../../../lib/psElements/psElements";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { PsTheme } from "../../../styles/psMuiTheme/colorCodes";

const CreateAccount: FunctionComponent = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    // setShowPassword(true);
  };

  const handleMouseUpPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    // setShowPassword(false);
  };

  const navigateToLoginByEmail = () => {
    navigate("/authorization/email");
  };
  const navigateToCongratulations = () => {
    navigate("/congratulations");
  };
  return (
    <Box>
      <GoBackButton handlerBackButton={navigateToLoginByEmail} />
      <Stack direction="column" sx={{ gap: "1.5rem" }}>
        <h1 className="psHeading h1">Create new Account</h1>
        <Stack direction="row" gap={"0.5rem"}>
          <PsInput type="text" label="First Name" />
          <PsInput type="text" label="Last Name" />
        </Stack>
        <PsInput type="email" label="Email Address" />
        <FormControl sx={{ m: 0, width: "100%" }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">
            Password
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label={
                    showPassword ? "hide the password" : "display the password"
                  }
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  onMouseUp={handleMouseUpPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>
        <PsInput type="password" label="Confirm Password" />
        <PsButton.Primary
          variant="contained"
          sx={{ height: "3rem" }}
          onClick={() => navigateToCongratulations()}
        >
          Create Account
        </PsButton.Primary>
        <Typography align="center">
          Already have a account?&nbsp;
          <span
            style={{ color: PsTheme.colors.action.primary }}
            className="cursorPointer"
          >
            Login
          </span>
        </Typography>
      </Stack>
      <Typography>
        By registering, you agree to the{" "}
        <span
          style={{ color: PsTheme.colors.action.primary }}
          className="cursorPointer"
        >
          Terms of Service and Privacy Policies,
        </span>{" "}
        including the use of Cookies
      </Typography>
    </Box>
  );
};
export default CreateAccount;
