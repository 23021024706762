import { FunctionComponent } from "react";
import StepsBar from "./StepsBar";
import { PsButton, PsInput, StepBox } from "../../../lib/psElements/psElements";
import { Grid2, Stack, Typography } from "@mui/material";
import { GoBackArrowIos } from "../../common/GoBackButton/GoBackButton";
import { useNavigate } from "react-router-dom";
import CountrySelect from "../../common/CountrySelect/CountrySelect";

const PersonalDetails: FunctionComponent = () => {
  const navigate = useNavigate();

  const navigateToCongratulations = () => {
    navigate("/Congratulations");
  };

  const navigateToSchoolDetails = () => {
    navigate("/register/steps/schooldetails");
  };

  return (
    <div className="personalDetails">
      <StepsBar currentStep={0} />
      <StepBox>
        <GoBackArrowIos
          handlerBackButton={() => {
            navigateToCongratulations();
          }}
          sx={{ ml: 2, mt: 2, mb: 1 }}
        />
        <Typography
          align="left"
          sx={{ ml: 3, fontWeight: 700, mb: 2.5 }}
          variant="h5"
        >
          Personal Details
        </Typography>
        <Grid2
          container
          spacing={{ xs: 2, md: 2.5 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          sx={{ m: 3, mb: 21.75 }}
        >
          <Grid2 size={{ md: 6 }} sx={{ mt: 1.5 }}>
            <PsInput label="First Name" />
          </Grid2>
          <Grid2 size={{ md: 6 }} sx={{ mt: 1.5 }}>
            <PsInput label="Last Name" />
          </Grid2>
          <Grid2 size={{ md: 6 }} sx={{ mt: 1.5 }}>
            <PsInput label="Email Address" type="email" />
          </Grid2>
          <Grid2 size={{ md: 6 }} sx={{ mt: 1.5 }}>
            <Stack direction={"row"} gap={3}>
              <CountrySelect />
              <PsInput label="Phone Number" />
            </Stack>
          </Grid2>
          <Grid2 size={{ md: 6 }} sx={{ mt: 1.5 }}>
            <PsInput label="Your City" />
          </Grid2>
          <Grid2 size={{ md: 6 }} sx={{ mt: 1.5 }}>
            <PsInput label="Birth Date" type="date" />
          </Grid2>
        </Grid2>
      </StepBox>
      <Stack direction={"row"} gap={1} sx={{ mt: 4.5 }} alignSelf={"flex-end"}>
        <PsButton.Outlined variant="outlined" sx={{ width: 120 }}>
          Cancel
        </PsButton.Outlined>
        <PsButton.Primary
          variant="contained"
          sx={{ width: 240 }}
          onClick={() => navigateToSchoolDetails()}
        >
          Proceed
        </PsButton.Primary>
      </Stack>
    </div>
  );
};
export default PersonalDetails;
